import { types, flow, getRoot, applySnapshot, getSnapshot} from 'mobx-state-tree';

import * as AppConfig from 'app.config';
import * as debug from 'utils/debugTool';


import { firestore } from './firebase';
import { ProfileFilterStore } from './FilterStore';
import { ImageBase, ImageProject } from './ImageBase';
import AddressModel from './AddressModel';

const CompactProjectModel = types
	.model('CompactProjectModel', {
		uid: types.string,
		projectName: '',
		defaultPhoto: types.maybe( ImageProject ),
		photoCount: 0,
		// city: '',
		// projectRooms: 0,
		// projectSize: 0,
	})
	.views( self => ({
		get __url(){
			return `${AppConfig.ROUTER_PROJECT_BASE}/${self.uid}`; 
		}
	}));

const ProfileModel = types
	.model('ProfileModel', {
		uid: types.string,
		url: '',
		// location
		building: types.optional( types.string, ''),
		address: types.optional( types.string, ''),
		town: types.optional( types.string, ''),
		city: types.optional( types.string, ''),	
		branches: types.array( AddressModel, []),		
		// profile information
		companyName: types.optional( types.string, ''),
		slogan: types.optional( types.string, ''),
		summary: types.optional( types.string, ''),
		email: types.optional( types.string, ''),
		fax: types.optional( types.string, ''),
		mobile: types.optional( types.string, ''),
		tel: types.optional( types.string, ''),
		website: types.optional( types.string, ''),
		workTypes: types.array( types.string, []),
		// medias
		logo: types.optional( ImageBase, {}),
		photos: types.array( ImageBase, []),
		projectCount: 0,
		isValid: false,
		// related projects and cursor
		projects: types.array( CompactProjectModel, []),
		lastCursor: types.optional( types.frozen(), {}),
		isProjectLoaded: false,
	})
	.views( self => ({
		get __ready(){
			return true;
		},
		get __pageTitle(){
			return self.companyName.toString();
		},
		get __hasProject(){
			return self.projectCount > 0 ? true : false;
		},
		get __projectLoaded(){
			if ( self.projects.length > 0 ) return true;
			return self.isProjectLoaded;
		},
		get __recentProjects(){
			return self.projects.slice(0, AppConfig.DEFAULT_PREVIEW_SIZE );
		},
		get __city(){
			const groupAttr = getRoot(self).attributeStore.getGroupByName('Locations');
			return groupAttr.getLabelById( self.city );
		},
		get __cityId(){
			return self.city;
		},
		get __url(){
			if ( self.url && self.url !== '') return `/${self.url}`;
			return `${AppConfig.ROUTER_PROFILE_BASE}/${self.uid}`;
		},
	}))
	.actions( self => ({
		afterCreate(){
			// self.fetchPhotos();
			// if ( self.projectCount > 0 ) self.fetchItems({});
		},
		fetchItems: flow (function* fetchItems({ cursor, limit = AppConfig.DEFAULT_COMPLETE_SIZE }){
			debug.store('ProfileStore::ProfileModel::fetchItems');
			if ( !self.__hasProject ) return;
			let projectQuery = firestore
				.collection('ProjectSnapshot')
				.where('professional.uid','==', self.uid)
				.orderBy('viewCount', 'desc')
				.orderBy('yearCompletion', 'desc')
				.limit( limit );
			//
			if ( cursor ) projectQuery = projectQuery.startAfter( cursor );
			try {
				const snapshot = yield projectQuery.get();
				const projectData = [];
				
				snapshot.docs.map( (project, index) => {
					const data = project.data();
					projectData.push(CompactProjectModel.create({
						uid: project.id,
						projectName: data.projectName,
						defaultPhoto: data.defaultPhoto,
					}));
					//
					if ( index === snapshot.size - 1 ) self.lastCursor = project;
					return;
				});
				applySnapshot( self.projects, projectData );
				return;
			} catch (error) {
				console.error(error);
				throw new Error(`There is problem while looking for related projects from professional ${self.companyName}`);
			}
		}),
		getDevelopedProjects({ pageNo=1 }){
			if ( !self.__ready || self.projects.length === 0) return { data: [], hasNext: false };
			//
			const itemSize = AppConfig.DEFAULT_PAGE_SIZE * pageNo;
			//
			if ( itemSize >= self.projects.length ) return {
				data: self.projects,
				hasNext: false
			};
			//
			return {
				data: self.projects.slice(0, itemSize),
				hasNext: true
			};
		},
		getDevelopedProjects_old: flow (function* getDevelopedProjects_old({ limit = AppConfig.DEFAULT_COMPLETE_SIZE }){
			if ( getRoot(self).projectStore.__ready ) {
				return getRoot(self).projectStore.getProjectsByProfileId( {uid: self.uid} );
			}
			//
			if ( self.projects.length >= self.projectCount ) {
				return {
					data: self.projects,
					hasNext: false,
				};
			}
			//
			let projectQuery = firestore
				.collection('ProjectSnapshot')
				.where('professional.uid','==', self.uid)
				.orderBy('yearCompletion', 'desc')
				.orderBy('lastModifiedAt', 'desc');

			if ( typeof self.lastCursor.exists === 'boolean' && self.lastCursor.exists ) {
				projectQuery = projectQuery.startAfter( self.lastCursor );
			}

			if ( limit ) {
				projectQuery = projectQuery.limit(limit);
			}
				
			try {
				const snapshot = yield projectQuery.get();
				const projectData = [];
				
				snapshot.docs.map( (project, index) => {
					projectData.push(CompactProjectModel.create({
						uid: project.id,
						projectName: project.data().projectName,
						defaultPhoto: project.data().defaultPhoto,
					}));
					//
					if ( index === snapshot.size - 1 ) self.lastCursor = project;
					return;
				});
				applySnapshot( self.projects, projectData );
				return {
					data: self.projects,
					hasNext: false,
				};
			} catch (error) {
				console.error(error);
				throw new Error(`There is problem while looking for related projects from professional ${self.companyName}`);
			}
		}),
	}));
	

const ProfileStore = types
	.model('ProfileStore', {
		isReady: false,
		profiles: types.array( ProfileModel, []),
		filters: types.optional( ProfileFilterStore, {}),
		isInitialLoaded: false,
		isReachEnd: false,
		lastCursor: types.optional( types.frozen(), {}),
	})
	.views( self => ({
		get __ready(){
			if ( self.profiles.length > 0 && self.isInitialLoaded ) return true;
			return false;
		},
		get __hasNext(){
			return !self.isReachEnd; 
		},
		get __filteredProfiles(){
			let profiles = self.profiles.filter( profile => profile );

			if ( self.filters.filterWork && self.filters.filterWork !== 'default' ) {
				profiles = profiles.filter( profile => profile.workTypes === self.filters.filterWork );
			}

			if ( self.filters.filterLocation && self.filters.filterLocation !== 'default' ) {
				profiles = profiles.filter( profile => profile.city === self.filters.filterLocation );
			}

			return profiles;
		},
	}))
	.actions( self => ({
		// afterCreate(){
		// },
		fetchItems: flow (function* fetchItems({ cursor }){
			// skip filtered options
			let query = firestore.collection('ProfileSnapshot');
			query = query
				.orderBy('viewCount', 'desc')
				.orderBy('companyName', 'asc')
				.limit(AppConfig.DEFAULT_COMPLETE_SIZE);
	
			if ( cursor ) query = query.startAfter( cursor );

			try {
				const snapshot = yield query.get();
				// check if the new page is available
				if ( snapshot.size < AppConfig.DEFAULT_COMPLETE_SIZE ||
					snapshot.empty ) {
					self.isReachEnd = true;
				}
				// if this is the first time initial
				if ( !self.isInitialLoaded ) {
					self.profiles = [];
				}
				// mapping new data into the 
				const items = [];
				snapshot.docs.map( (item, index) => {
					items.push({
						...item.data(),
						uid: item.id,
						isValid: true
					});
					if ( index === snapshot.size - 1) self.lastCursor = item;
					return;
				}); 
				applySnapshot( self.profiles, items);
				self.isInitialLoaded = true;
				return true;
			} catch (error) {
				// console.error(error);
				throw new Error('There is error while fetching');
			}
		}),
		fetchNext: flow ( function* fetchNext(){
			if ( !self.__hasNext ) return false;
			try{
				yield self.fetchItems({
					cursor: self.lastCursor
				});
				return true;
			} catch (error) {
				// console.error(error);
				throw new Error('There is error while fetching');
			}
		}),
		getDetailById: flow ( function* getDetailById(uid){
			const foundItem = self.profiles.find( item => item.uid === uid );
			if (foundItem) {
				fetch(`${AppConfig.API_REST_VIEWS}/professionals/${uid}`, {
					method: 'POST',
					credentials: 'include'
				});
				return foundItem;
			}
			//
			// console.log('fetching from server the profile')
			const projectRef = firestore.doc(`ProfileSnapshot/${uid}`);

			try {
				// console.log( 'fetching from server the id ', uid );
				const doc = yield projectRef.get();
				
				if ( doc.exists ) {
					self.profiles.push( ProfileModel.create({
						...doc.data(),
						uid: doc.id,
						isValid: true,
					}));
					fetch(`${AppConfig.API_REST_VIEWS}/professionals/${uid}`, {
						method: 'POST',
						credentials: 'include'
					});
					return self.profiles[ self.profiles.length - 1 ];
				} else {
					return ProfileModel.create({
						uid: 'not-found',
						isValid: false
					});
				}
			} catch (e) {
				// console.error(e);
				throw `Please see the issue ${e}`;
			}
		}),
		getDetailByUrl: flow ( function* getDetailByUrl(url){
			const foundItem = self.profiles.find( item => item.url === url );
			if (foundItem) {
				fetch(`${AppConfig.API_REST_VIEWS}/professionals/${foundItem.uid}`, {
					method: 'POST',
					credentials: 'include'
				});
				return foundItem;
			}
			//
			const query = firestore
				.collection('ProfileSnapshot')
				.where('url', '==', url)
				.limit(1);

			try {
				const result = yield query.get();
				if ( result.size === 0 ) 
					return ProfileModel.create({
						uid: 'not-found',
						isValid: false
					}); 
				// console.log( 'fetching from server the id ', uid );
				const doc = result.docs[0];
				self.profiles.push( ProfileModel.create({
					...doc.data(),
					uid: doc.id,
					isValid: true,
				}));
				//
				fetch(`${AppConfig.API_REST_VIEWS}/professionals/${doc.id}`, {
					method: 'POST',
					credentials: 'include'
				});
				//
				return self.profiles[ self.profiles.length - 1 ];	
			} catch (e) {
				// console.error(e);
				throw `Please see the issue ${e}`;
			}
		}),
		getListByFilters({filters, sortBy='', pageNo=1}){
			if ( !self.__ready ) return { data: [], hasNext: false };
			applySnapshot( self.filters, filters );
			let profiles = self.profiles.filter( profile => profile );
			//
			if ( sortBy !== '' ){
				switch (sortBy){
				case 'name-asc': 
					profiles = profiles.sort( (a,b) => {
						if ( b.companyName < a.companyName ) return 1;
						if ( b.companyName > a.companyName ) return -1;
						return 0;
					});
					break;
				case 'name-desc': 
					profiles = profiles.sort( (a,b) => {
						if ( a.companyName < b.companyName ) return 1;
						if ( a.companyName > b.companyName ) return -1;
						return 0;
					});
					break;
				case 'project-asc': 
					profiles = profiles.sort( (a,b) => a.projectCount - b.projectCount );
					break;
				case 'project-desc': 
					profiles = profiles.sort( (a,b) => b.projectCount - a.projectCount );
					break;
				default: 
					break;
				}
			}
			//
			if ( self.filters.filterWork && self.filters.filterWork !== 'default' ) {
				profiles = profiles.filter( profile => profile.workTypes.indexOf(self.filters.filterWork) >= 0 );
			}

			if ( self.filters.filterLocation && self.filters.filterLocation !== 'default' ) {
				profiles = profiles.filter( profile => profile.city === self.filters.filterLocation );
			}
			//
			const itemSize = AppConfig.DEFAULT_PAGE_SIZE * pageNo;
			//
			if ( itemSize >= profiles.length ) return {
				data: profiles,
				hasNext: false
			};
			//
			return {
				data: profiles.slice(0, itemSize),
				hasNext: true
			};
		}
	}));

export { ProfileModel, ProfileStore };