import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { SiteStore } from './models/SiteStore';
import App from './App';


const rootStore = SiteStore.create({});



ReactDOM.render( 
	<Provider siteStore={rootStore} >
		<App store={rootStore} />
	</Provider>,
	document.getElementById('root')
);