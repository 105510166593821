export function setPageTitle({title, isTrail=true}){
	if ( typeof title !== 'undefined' && title !== '' ){

		window.document.title = isTrail ? `${ title } - Morph` : title;
	} else {
		window.document.title = 'Morph';
	}
}

export function setTagLanguage({lang}){
	document.documentElement.lang = lang
	document.documentElement.className = `lang-${lang}`;
}

export function isNotEmpty(value) {
	if (typeof value === "undefined") return false;
	if (!value || value === "") return false;
	return true;
}
