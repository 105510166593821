import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/firestore';

const config = {
	apiKey: process.env.REACT_APP_FIRE_API_KEY,
	authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIRE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIRE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID
};

if (!firebase.apps.length) {
	firebase.initializeApp(config);
}

const firestore = firebase.firestore();

export { 
	firestore,
};