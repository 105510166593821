import { types, flow } from 'mobx-state-tree';
import { STORAGE_LANG } from './global';
import { ProjectStore } from './ProjectStore';
import { ProfileStore } from './ProfileStore';
import { LocaleStore } from './LanguageStore';
import { AttributeStore } from './AttributeStore';
import { CarouselStore } from './CarouselStore';
import { UrlStore } from './PremiumUrl';

export const SiteStore = types
	.model('SiteStore', {
		projectStore: types.optional(ProjectStore, {}),
		profileStore:types.optional(ProfileStore, {}),
		localeStore: types.optional( LocaleStore, {}),
		attributeStore: types.optional( AttributeStore, {}),
		banners: types.optional( CarouselStore, {} ),
		urlStore: types.optional( UrlStore, {} ),
	})
	.views( self => ({
		get __ready(){
			return self.localeStore.isReady && self.attributeStore.isReady && self.urlStore.isReady;
		},
		get __lang(){
			return self.localeStore.lang || 'en';
		},
		get __urls(){
			return self.urlStore.isReady ? self.urlStore.list : [];
		}
	}))
	.actions( self => ({
		afterCreate(){
			//initial fetching content
			// self.localeStore = LanguageStore.create({
			// 	lang: localStorage.getItem( STORAGE_LANG ) || ''
			// });
			self.localeStore = LocaleStore.create({});
			//
			self.attributeStore = AttributeStore.create({});
			//
			self.projectStore = ProjectStore.create({});
			// self.projectStore.fetchAll();
			self.profileStore = ProfileStore.create({});
			// self.profileStore.fetchAll();
			// self.isReady = true;
			self.urlStore = UrlStore.create({});
			self.urlStore.fetchAll();
		},
		getStoreByName(name){
			if ( name === 'professionals' ) return self.profileStore;
			return self.projectStore;
		},
		getPremiumUrls(urlParams){
			if ( !self.__ready ){
				return self.urlStore.getUidFromUrl(urlParams);
			}
			return false;
		},
		retriveBannerSlides(){
			if ( self.banners.isReady ) return self.banners;
			self.banners = CarouselStore.create();
			self.banners.fetchAll();
			// return self.banners;
		},
		changeLanguage(langId){
			self.lang = langId;
		}	
	}));