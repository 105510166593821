import { types } from 'mobx-state-tree';
import { 
	MEDIA_CAROUSEL_DEFAULT, 
	MEDIA_THUMB_DEFAULT, 
	IMGIX_BASE } from './global';

const ImageBase = types
	.model({
		fullPath:'',
		isDefault: false,
		settings: '',
	})
	.views( self => ({
		get isValidImage(){
			return self.fullPath !== '' ? true : false;
		},
		get imgPath(){
			return self.isValidImage ? `${IMGIX_BASE}/${self.fullPath}` : MEDIA_CAROUSEL_DEFAULT;
		},
		get imgThumb(){
			const carouselSetting = 'auto=enhance&format=auto&dpr=2&crop=edges&w=320&h=240';
			return self.isValidImage ? `${IMGIX_BASE}/${self.fullPath}?${carouselSetting}` : MEDIA_THUMB_DEFAULT;
		},
		get imgCarousel(){
			const carouselSetting = 'auto=enhance&fm=pjpg&q=75&dpr=2&crop=edges&h=576';
			return self.isValidImage ? `${IMGIX_BASE}/${self.fullPath}?${carouselSetting}` : MEDIA_CAROUSEL_DEFAULT;
		},
		get __imgUHDBkg(){
			//const carouselSetting = 'auto=enhance&fm=pjpg&q=75&dpr=2&crop=edges&w=1440&h=810';
			const carouselSetting = 'auto=enhance&fm=pjpg&q=75&dpr=2&crop=edges&w=1440&h=617';
			return self.isValidImage ? `${IMGIX_BASE}/${self.fullPath}?${carouselSetting}` : MEDIA_CAROUSEL_DEFAULT;
		},
		get __imgHQBkg(){
			//const carouselSetting = 'auto=enhance&fm=pjpg&q=75&dpr=2&crop=edges&w=1024&h=576';
			const carouselSetting = 'auto=enhance&fm=pjpg&q=75&dpr=2&crop=entropy&fit=crop';
			return self.isValidImage ? `${IMGIX_BASE}/${self.fullPath}?${carouselSetting}` : MEDIA_CAROUSEL_DEFAULT;
		},
		get __imgMDBkg(){
			//const carouselSetting = 'auto=enhance&fm=pjpg&q=75&dpr=2&crop=edges&fit=crop&w=540&h=405';
			const carouselSetting = 'auto=enhance&fm=pjpg&q=75&dpr=2&crop=edges&fit=crop&w=540&h=360';
			return self.isValidImage ? `${IMGIX_BASE}/${self.fullPath}?${carouselSetting}` : MEDIA_THUMB_DEFAULT;
		},
		get __imgThumbBkg(){
			const carouselSetting = 'auto=enhance&fm=pjpg&q=85&dpr=2&crop=edges&fit=crop&w=360&h=240';
			return self.isValidImage ? `${IMGIX_BASE}/${self.fullPath}?${carouselSetting}` : MEDIA_THUMB_DEFAULT;
		},
		get __imageLogo(){
			const carouselSetting = 'format=auto&dpr=2&fit=max&w=400&h=400';
			return self.isValidImage ? `${IMGIX_BASE}/${self.fullPath}?${carouselSetting}` : MEDIA_THUMB_DEFAULT;
		},
		get __imageThumbLogo(){
			const carouselSetting = 'format=auto&dpr=2&fit=max&w=180&h=180';
			return self.isValidImage ? `${IMGIX_BASE}/${self.fullPath}?${carouselSetting}` : MEDIA_THUMB_DEFAULT;
		},
		get __imageMiniLogo(){
			if ( self.fullPath === '') return  MEDIA_THUMB_DEFAULT;
			// console.log('self.fullPath', typeof self.fullPath);
			const carouselSetting = 'format=auto&dpr=2&fit=max&w=100&h=100';
			return self.isValidImage ? `${IMGIX_BASE}/${self.fullPath}?${carouselSetting}` : MEDIA_THUMB_DEFAULT;
		}
	}))
	.actions( self => ({
		getImage(settings){
			return self.isValidImage ? `${IMGIX_BASE}/${self.fullPath}?${settings}` : MEDIA_CAROUSEL_DEFAULT; 
		}
	}));

const ImageProject = types.compose(
	ImageBase,
	types.model('ImageProject', 
		{
			photoFunction: types.array( types.string, []),
		})
		.actions( self => ({
			hasFunctionType( funcName ){
				if ( funcName === 'default' || typeof funcName === 'undefined') return true;
				return self.photoFunction.indexOf(funcName) >= 0 ? true : false;
			}
		}))
);
export {ImageBase, ImageProject};