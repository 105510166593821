export const ROUTER_BASE = '/';
export const ROUTER_PROFILE_BASE = '/professionals';
export const ROUTER_PROJECT_BASE = '/projects';
export const ROUTER_PROJECT_DETAIL = '/projects/view';
export const ROUTER_CONTACT_BASE = '/contact';
export const ROUTER_QUOTE_BASE = '/quote';
export const ROUTER_QUOTE_PROFILE = '/quote/professional'

export const MEDIA_ROOT = '/static/images';
export const MEDIA_SVG = '/static/images/vectors';
export const MEDIA_THUMB_DEFAULT = MEDIA_ROOT + '/image-default@2x.png';
export const MEDIA_CAROUSEL_DEFAULT = MEDIA_ROOT + '/image-carousel-default@2x.png';

export const API_ROOT = process.env.REACT_APP_API_REF;
export const API_REST_QUOTE = API_ROOT + '/v1/quotes';
export const API_REST_LANG = API_ROOT + '/v1/languages';
export const API_REST_FILTERS = API_ROOT + '/v1/filters';
export const API_REST_VIEWS = API_ROOT + '/v1/views';

export const SITE = 'Morph';
// STORAGE
export const STORAGE_LANG = 'lang';
export const STORAGE_LANG_LABELS = 'lang_labels';
export const STORAGE_LANG_VERSION = 'lang_version';
export const STORAGE_LANG_LOCALES = 'lang_locales';
export const STORAGE_LANG_EXPIRED = 'lang_expiry';
//
export const DEFAULT_COMPLETE_SIZE = 100;
export const DEFAULT_PAGE_SIZE = 9;
export const DEFAULT_PREVIEW_SIZE = 6;
export const IMGIX_BASE = process.env.REACT_APP_IMGIX_REF;
// TAGS
export const TAG_PROJECT_DETAIL_URL = 'https://morph.casa/projects/view';
export const TAG_PROFILE_DETAIL_URL = 'https://morph.casa/professionals/view';

