import React from 'react';
import * as AppConfig from './app.config';

const SiteRoutes = [
	{
		name: 'home',
		title: 'Morph',
		routeConfig: AppConfig.ROUTER_BASE,
		routeType: 'exact',
		componentPath: './pages/Homepage'
	},
	{
		name: 'projectList',
		title: 'Projects - Morph',
		routeConfig: AppConfig.ROUTER_PROJECT_BASE,
		routeType: 'exact',
		componentPath: './pages/Projects',
		component: ({siteStore, locale, RouteComponent}) => <RouteComponent 
			pageStore={ siteStore.projectStore }
			attributeStore={ siteStore.attributeStore } 
			localeStore= { siteStore.localeStore }
			locale={ locale }
		/>

		// pageStore={ projectStore }
		// 		attributeStore={ attributeStore }
		// 		filterParams={ projectFilterParams }
		// 		localeStore= { localeStore }
		// 		functionViewer={ filterFunction }
		// 		styleViewer={ filterStyle }
		// 		listMode={ listMode === 'list' ? 'list' : 'card' } 
		// 		onChangeListMode = { onChangeListMode } 
		// 		onChangeSortBy = { props.onChangeSortBy }
		// 		sortBy={ sortBy } />;
	},
	{
		name: 'projectDetail_Redirect',
		title: 'Projects - Morph',
		routeConfig: `${AppConfig.ROUTER_PROJECT_BASE}/view/:id`,
		routeType: 'redirect',
		redirectTo: `${AppConfig.ROUTER_PROJECT_BASE}/:id`
	},
	{
		name: 'projectDetail',
		title: 'Project details - Morph',
		routeConfig: `${AppConfig.ROUTER_PROJECT_BASE}/:id`,
		routeType: 'strict',
		componentPath: './pages/ProjectDetail',
		component: ({routeProps, siteStore, RouteComponent}) => <RouteComponent 
			history={ routeProps.history } 
			uid={ routeProps.match.params.id } 
			pageStore={ siteStore.projectStore }
			localeStore= { siteStore.localeStore }
		/>
	},
	{
		name: 'professionalList',
		title: 'Professionals - Morph',
		routeConfig: AppConfig.ROUTER_PROFILE_BASE,
		routeType: 'exact',
		componentPath: './pages/Profiles',
		component: ({siteStore, locale, RouteComponent}) => <RouteComponent 
			pageStore={ siteStore.profileStore }
			attributeStore={ siteStore.attributeStore } 
			localeStore= { siteStore.localeStore }
			locale={ locale }
		/>
	},
	{
		name: 'professionalDetail_Redirect',
		title: 'Professional details - Morph',
		routeConfig: `${AppConfig.ROUTER_PROFILE_BASE}/view/:id`,
		routeType: 'redirect',
		redirectTo: `${AppConfig.ROUTER_PROFILE_BASE}/:id`
	},
	{
		name: 'professionalDetail',
		title: 'Professional details - Morph',
		routeConfig: `${AppConfig.ROUTER_PROFILE_BASE}/:id`,
		routeType: 'strict',
		componentPath: './pages/ProfileDetail',
		component: ({routeProps, siteStore, RouteComponent}) => <RouteComponent 
			history={ routeProps.history } 
			location={ routeProps.location }
			uid={ routeProps.match.params.id } 
			pageStore={ siteStore.profileStore }
			localeStore= { siteStore.localeStore }
		/>
	},
	// {
	// 	name: 'professionalDetail',
	// 	title: 'Professional details - Morph',
	// 	routeConfig: `${AppConfig.ROUTER_PROFILE_BASE}/view/:id`,
	// 	routeType: 'strict',
	// 	componentPath: './pages/ProfileDetail',
	// 	component: ({match, siteStore, locale, RouteComponent}) => <RouteComponent 
	// 		match={ match } 
	// 		siteStore={ siteStore } 
	// 		locale={ locale }
	// 		url={null}
	// 		uid={match.params.id}
	// 	/>
	// },
	{
		name: 'quote',
		title: 'Get free quotes - Morph',
		routeConfig: AppConfig.ROUTER_QUOTE_BASE,
		routeType: 'exact',
		componentPath: './pages/Quote',
		component: ({routeProps, siteStore, locale, RouteComponent}) => <RouteComponent 
			state={ routeProps.history.location.state }
			siteStore={ siteStore } 
			locale={ locale }
			isProfileMode={ false }
		/>
	},
	{
		name: 'quoteByProfile',
		title: 'Get free quotes - Morph',
		routeConfig: AppConfig.ROUTER_QUOTE_PROFILE,
		routeType: 'exact',
		componentPath: './pages/Quote',
		component: ({routeProps, siteStore, locale, RouteComponent}) => <RouteComponent 
			state={ routeProps.history.location.state }
			siteStore={ siteStore } 
			locale={ locale }
			isProfileMode={ true }
		/>
	},
	// {
	// 	name: 'notFound',
	// 	title: 'Not foun - Morph',
	// 	routeConfig: '**',
	// 	componentPath: './pages/NotFound',
	// },
];

export default SiteRoutes;