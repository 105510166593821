import { types, flow, getParent } from 'mobx-state-tree';

import * as Global from 'app.config';
import * as debug from 'utils/debugTool';


const LabelModel = types
	.model('LabelModel',{
		id: types.string,
		value: types.string
	});
	
const LocaleModel = types
	.model('LocaleModel',{
		id: types.string,
		label: '',
		labels: types.array( LabelModel, [])
	})
	.views( self => ({
		get __active(){
			return getParent(self, 2).lang === self.id;
		}
	}))
	.actions( self => ({
		getLabelById(labelId){
			const found = self.labels.find( item => item.id === labelId );
			return found ? found.value : '';
		},
		changeLanguage(){
			getParent(self, 2).changeLanguage( self.id );
		}
	}));

export const LocaleStore = types
	.model('LocaleStore',{
		locales: types.array( LocaleModel, []),
		lang: 'en',
		isReady: false
	})
	.views( self => ({
		get __localeList(){
			const list = [];
			self.locales.map( locale => list.push({
				id: locale.id,
				label: locale.label,
				active: self.lang === locale.id ? true : false
			}));
			return list;
		},
		get __activeLangLabel(){
			const found = self.locales.find( locale => locale.id === self.lang );
			return found ? found.label : '';
		}
	}))
	.actions( self => ({
		afterCreate(){
			// init the preferred language
			const langSelected = localStorage.getItem( Global.STORAGE_LANG);
			if (langSelected !== null ){
				self.lang = langSelected;
			} else {
				localStorage.setItem( Global.STORAGE_LANG, 'en' );
			}
			self.fetchItems();
		},
		fetchItems: flow( function* fetchItems(){
			try{
				const result = yield window.fetch( Global.API_REST_LANG, { credentials: 'include'});
				const data = yield result.json();
				data['@metadata'].locales.map( item => {
					const labelData = Object.keys(data[item.id]).reduce(
						(source, keyName) => {
							source.push({
								id: keyName,
								value: data[item.id][keyName]
							});
							return source;
						}, []
					);

					self.locales.push(
						LocaleModel.create({
							id: item.id,
							label: item.label,
							labels: labelData
						})
					);
				});
				self.isReady = true;
				return;
			} catch (error) {
				debug.store('LanguageStore:fetchItems: error', error);
				throw new Error('There is error while getting file content');
			}
		}),
		getLabelById(labelId){
			const currentLocale = self.locales.find( locale => locale.id === self.lang );
			if ( currentLocale ) return currentLocale.getLabelById(labelId);
			return '';
		},
		changeLanguage(id){
			if ( id !== self.lang ){
				self.lang = id;
				localStorage.setItem( Global.STORAGE_LANG, id );
			}
		}
	}));