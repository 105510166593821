import { types, flow } from 'mobx-state-tree';

import { STORAGE_LANG, API_REST_FILTERS } from './global';

const LocaleModel = types
	.model('LocaleModel',{
		id: types.string,
		labels: types.array( 
			types.model({
				id: types.string,
				value: types.string
			}), 
			[]
		)
	})
	.views( self => ({
		//
	}))
	.actions( self => ({
		getValueId(labelId){
			const found = self.labels.find( item => item.id === labelId );
			return found ? found.value : '';
		}
	}));	

const AttributeGroupModel = types
	.model('AttributeGroupModel',{
		name: types.string,
		locales: types.array( LocaleModel, []),
		options: types.array( 
			types.model({
				value: types.string,
				param: ''
			}), 
			[]
		)
	})
	.views( self => ({
		get __lang(){
			const langSelected = localStorage.getItem(STORAGE_LANG);
			return langSelected || 'en';
		},
		get __optionList(){
			const list = [];
			self.options.map( option => list.push({
				value: option.value,
				label: self.getLabelById(option.value)
			}));
			return list;
		},
		get __optionNoDefaultList(){
			const list = [];
			self.options.map( option => option.value !== 'default' && list.push({
				value: option.value,
				label: self.getLabelById(option.value)
			}));
			return list;
		}
	}))
	.actions( self => ({
		getOptionById(labelId){
			return {id: labelId, defaultName: 'Undefined'};
			// const found = self.options.find( item => item.value === labelId );
			// return found ? {id: labelId, label: item. } : OptionItemModel.create({id: labelId, defaultName: 'Undefined'});
		},
		getLabelById(labelId){
			const locale = self.locales.find( localeItem => localeItem.id === self.__lang );
			if ( !locale ) return '';
			//
			return locale.getValueId(labelId);
		},
		getParamById(labelId){
			const found = self.options.find( item => item.value === labelId );
			return found ? found.param : '';
		}
	}));


export const AttributeStore = types
	.model('AttributeStore', {
		filterGroups: types.array( AttributeGroupModel, []),
		isReady: false
	})
	.views( self => ({
		//
	}))
	.actions( self => ({
		afterCreate(){
			self.fetchItems();
		},
		fetchItems: flow( function* fetchItems(){
			try{
				const result = yield window.fetch( API_REST_FILTERS, { credentials: 'include'});
				const data = yield result.json();

				const filterCollections = data['@metadata'].filterCollections;
				const localeList = ['en', 'vi', 'cn'];

				Object.keys(filterCollections).map( filterName => {					
					const filterCollection = {
						name: filterName,
						locales: [],
						options: filterCollections[filterName]
					};
					// loop 'en', 'vi', 'cn'				
					localeList.map( langId => {
						const newLocale = {
							id: langId,
							labels: []
						};
						// loop en: { filterName : { optionId} }
						Object.keys( data[langId][filterName] ).map( optionId => 
							newLocale.labels.push({
								id: optionId,
								value: data[langId][filterName][optionId]
							})
						);
						filterCollection.locales.push( newLocale );
					});
					//
					self.filterGroups.push(
						AttributeGroupModel.create(filterCollection)
					);
				});
				self.isReady = true;
				return;
			} catch (error) {
				console.error( error );
				throw new Error('There is error while getting file content');
			}
		}),
		getGroupByName(groupName){
			const foundGroup = self.filterGroups.find( group => group.name === groupName );
			if ( foundGroup ) return foundGroup;
			return AttributeGroupModel.create({
				name: groupName
			});
		}
	}));
