import { types, flow, applySnapshot } from 'mobx-state-tree';
import { ImageBase } from './ImageBase';
import { firestore } from './firebase';

import * as Global from 'app.config';
import { MEDIA_CAROUSEL_DEFAULT } from './global';

const ProfileRefModel = types
	.model('ProfileRefModel',{
		uid: types.string,
		companyName: types.string,
		url: types.maybeNull( types.string )
	})
	.views( self => ({
		get __existed(){
			return self.uid !== '' && self.companyName !== '';
		},
		get __url(){
			if ( self.url !== null) return `/${self.url}`;
			return `${Global.ROUTER_PROFILE_BASE}/${self.uid}`
		}
	}));
const ProjectRefModel = types
	.model('ProjectRefModel',{
		uid: '',
		projectName: '',
	})
	.views( self => ({
		get __existed(){
			return self.uid !== '' && self.projectName !== '';
		},
		get __url(){
			return `${Global.ROUTER_PROJECT_BASE}/${self.uid}`
		}
	}));
const CarouselModel = types
	.model('CarouselModel', {
		label: '',
		// type: types.enumeration('ItemTypes',['Project', 'Professional', 'Ads']),
		photo: types.maybe( ImageBase ),
		project: types.maybeNull( ProjectRefModel ),
		professional: types.maybeNull( ProfileRefModel ),
		indexOrder: 0
	});

export const CarouselStore = types
	.model('CarouselStore',{
		items: types.array(CarouselModel, []),
		isReady: false,
		collectionName: 'BannerSnapshot'
	})
	.views( self => ({
		get __slides(){
			if ( self.isReady ) return self.items;
			return [];
		}
	}))
	.actions( self => ({
		fetchAll: flow ( function* fetchAll(){
			const query = firestore.collection(self.collectionName)
				.orderBy('indexOrder', 'desc')
				.limit(6);

			try {
				const snapshot = yield query.get();
				const results = [];
				snapshot.docs.map( item => self.items.push( item.data() ) );
				// snapshot.docs.map( item => results.push( item.data() ) );
				// applySnapshot( self.items, results);
				self.isReady = true;
			} catch (error) {
				console.error( error );
				throw new Error('There is something wrong from your request for ads content');
			}
		}),
	}));