import React from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { RouterToUrlQuery } from 'react-url-query';

import AppRoutes from './routes';
import * as debug from './utils/debugTool';
import { setTagLanguage } from './utils/functions';

class PageLazyPreloader extends React.PureComponent {
	render() {
		return (
			<div className="lazy-component">
				<div className="lazy-animate__bg" />
			</div>
		);
	}
}
class App extends React.Component {
	render() {
		
		const { __lang, __ready, __urls } = this.props.store;
		setTagLanguage({lang: __lang});
		return (
			<React.Fragment>
				{ __ready &&
				<div
					id="app-container"
					className="app-container"
				>
					<Router>
						<RouterToUrlQuery>
							<Switch>
								{AppRoutes.map((routeItem, index) => {
									if ( routeItem.routeType === 'redirect')
										return <Redirect key={index} from={routeItem.routeConfig} to={routeItem.redirectTo} />
									//
									const LazyLoadedComponent = React.lazy(() => import(`${routeItem.componentPath}`));
									return <Route
										key={index}
										exact={routeItem.routeType === 'exact' ? true : false}
										strict={routeItem.routeType === 'strict' ? true : false}
										path={routeItem.routeConfig}
										component={ (route) => <React.Suspense fallback={<PageLazyPreloader />} >
											{ routeItem.component ? 
												routeItem.component({
													routeProps: route,
													siteStore: this.props.store,
													locale: __lang,
													RouteComponent: LazyLoadedComponent
												}) :
												<LazyLoadedComponent
													siteStore={ this.props.store }
													routeProps={ route }
													locale={ __lang }
												/>
											}
										</React.Suspense> 
										}
									/>;
								})}
								{ __urls.map( (routeItem, index) => {
									const LazyLoadedComponent = React.lazy(() => import('./pages/ProfileDetail'));
									return <Route
										key={`premium_${index}`}
										exact
										path={ `/${routeItem.url}` }
										component={ (route) => <React.Suspense fallback={<PageLazyPreloader />} >
											<LazyLoadedComponent
												location={ route.location }
												history={ route.history } 
												uid={ routeItem.uid } 
												pageStore={ this.props.store.profileStore }
												localeStore= { this.props.store.localeStore }
											/>
										</React.Suspense> 
										}
									/>;
								})}
							</Switch>
						</RouterToUrlQuery>
					</Router>
				</div>
				}
				{ !__ready && <PageLazyPreloader /> }
			</React.Fragment>
		);
	}
}

export default observer(App);