export const APP_HOST = process.env.REACT_APP_HOST || "";
// ROUTE DEFINE
export const ROUTER_BASE = '/';
export const ROUTER_PROFILE_BASE = '/professionals';
export const ROUTER_PROJECT_BASE = '/projects';
export const ROUTER_PROJECT_DETAIL = '/projects/view';
export const ROUTER_CONTACT_BASE = '/contact';
export const ROUTER_QUOTE_BASE = '/quote';
export const ROUTER_QUOTE_PROFILE = '/quote/professional';

// REST API
export const API_ROOT = process.env.REACT_APP_API_REF;
export const API_REST_QUOTE = API_ROOT + '/v1/quotes';
export const API_REST_LANG = API_ROOT + '/v1/languages';
export const API_REST_FILTERS = API_ROOT + '/v1/filters';
export const API_REST_VIEWS = API_ROOT + '/v1/views';

// MEDIA
export const MEDIA_ROOT = '/static/images';
export const MEDIA_SVG = '/static/images/vectors';
export const MEDIA_THUMB_DEFAULT = MEDIA_ROOT + '/image-default@2x.png';
export const MEDIA_CAROUSEL_DEFAULT = MEDIA_ROOT + '/image-carousel-default@2x.png';

// TAGS
export const TAG_MORPH_LOGO_URL = `${APP_HOST}/static/images/logo-morphcasa.png`;
export const TAG_PROJECT_URL = `${APP_HOST}/projects`;
export const TAG_PROFILE_URL = `${APP_HOST}/professionals`;

// SIZE & NUMBER
export const DEFAULT_COMPLETE_SIZE = 100;
export const DEFAULT_PAGE_SIZE = 9;
export const DEFAULT_PREVIEW_SIZE = 3;
export const IMGIX_BASE = process.env.REACT_APP_IMGIX_REF;

// STORAGE
export const STORAGE_LANG = 'lang';
export const STORAGE_LANG_LABELS = 'lang_labels';
export const STORAGE_LANG_VERSION = 'lang_version';
export const STORAGE_LANG_LOCALES = 'lang_locales';
export const STORAGE_LANG_EXPIRED = 'lang_expiry';