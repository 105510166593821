import { types, flow, applySnapshot } from 'mobx-state-tree';
import { firestore } from './firebase';

const UrlModel = types.model('UrlModel', {
	uid: types.string,
	url: types.string
});

const UrlStore = types
	.model('UrlStore',{
		list: types.array( UrlModel, []),
		isReady: false
	})
	.actions( self => ({
		fetchAll: flow (function* fetchAll(){
			const collectionRef = firestore.collection('PremiumUrls');
			try {
				const snapshot = yield collectionRef.get();
				const resultList = [];
				if ( snapshot.size > 0) {
					snapshot.docs.map(item => resultList.push({
						uid: item.id,
						url: item.data().url
					}));
					applySnapshot( self.list, resultList );
				}
				self.isReady = true;
			} catch (error) {
				// self.isReady = true;
				console.error(error);
			}
		}),
		getUidFromUrl(urlParam){
			const result = self.list.find( item => item.url === urlParam );
			if ( result ) return result;
			return false;
		}
	}));

export { UrlStore, UrlModel };
