import { types, getRoot } from 'mobx-state-tree';

const AddressModel = types
	.model('AddressModel',{
		branchName: '',
		address: types.optional( types.string, ''),
		building: types.optional( types.string, ''),
		town: types.optional( types.string, ''),
		city: types.optional( types.string, ''),
		email: '',
		tel: '',
		fax: '',
		mobile: ''
	})
	.views( self => ({
		get __city(){
			const groupAttr = getRoot(self).attributeStore.getGroupByName('Locations');
			return groupAttr.getLabelById( self.city );
		},
	}));

export default AddressModel;