import { types, flow, getParent, applySnapshot, getRoot } from 'mobx-state-tree';
import { firestore } from './firebase';

import { 
	DEFAULT_COMPLETE_SIZE, 
	DEFAULT_PAGE_SIZE, 
	DEFAULT_PREVIEW_SIZE,
	API_REST_VIEWS,
	ROUTER_PROFILE_BASE,
} from './global';
import { ProjectFilterStore } from './FilterStore';
import { ImageProject, ImageBase } from './ImageBase';
import { ROUTER_PROJECT_BASE } from '../app.config';

const CompactProfileModel = types
	.model({
		uid: types.string,
		companyName: '',
		logo: types.optional( ImageBase, {}),
		summary: '',
		address:'',
		town: '',
		city: '',
		website: '',
		projectCount: 0,
		url: '',
	})
	.views( self => ({
		get __url(){
			if ( self.url && self.url !== '') return `/${self.url}`;
			return `${ROUTER_PROFILE_BASE}/${self.uid}`;
		},
		get __city(){
			const groupAttr = getRoot(self).attributeStore.getGroupByName('Locations');
			return groupAttr.getLabelById( self.city );
		},
	}));	

const CompactProjectModel = types
	.model('CompactProjectModel', {
		uid: types.string,
		projectName: '',
		defaultPhoto: types.maybe( ImageProject ),
		photoCount: 0,
		// city: '',
		// projectRooms: 0,
		// projectSize: 0,
	})
	.views( self => ({
		get __url(){
			return `${ROUTER_PROJECT_BASE}/${self.uid}`; 
		}
	}));

const ProjectModel = types
	.model('ProjectModel', {
		uid: types.string,
		professional: types.maybe( CompactProfileModel ),
		// location
		location: '',
		city: types.optional( types.string, ''),
		// project information
		projectName: types.optional( types.string, ''),
		projectCost: types.optional( types.number, 0),
		projectRooms: types.union( types.string, types.number ),
		projectSize: types.optional( types.number, 0),
		yearCompletion: types.union( types.string, types.number ),
		summary: types.optional( types.string, ''),
		// project feature attributes
		workTypes: types.array( types.string, []),
		projectStyle: types.array( types.string, []),
		projectType: '',
		projectCondition: '',
		projectFunction: types.array( types.string, []),
		// project images
		photoCount: 0, 
		photos: types.array( ImageProject, []),
		defaultPhoto: types.maybe( ImageProject ),
		relatedProjects: types.array( CompactProjectModel, [] ),
		isRelatedLoaded: false,
		isValid: false
	})
	.views( self => ({
		get __ready(){
			return true;
		},
		get __pageTitle(){
			const localeStore = getRoot(self).localeStore;
			if ( localeStore.isReady ){
				return `${self.projectName} ${localeStore.getLabelById('page__title_breaker')} ${ self.professional.companyName.toString() }`
			}
			return `${self.projectName} - ${ self.professional.companyName.toString().toUpperCase() }`
		},
		get __relatedProjectReady(){
			if ( self.relatedProjects.length > 0 && self.isRelatedLoaded ) return true;			
			return false;
		},
		get __city(){
			const groupAttr = getRoot(self).attributeStore.getGroupByName('Locations');
			return groupAttr.getLabelById( self.city );
		},
		get __cityId(){
			return self.city;
		},
		get __type(){
			const groupAttr = getRoot(self).attributeStore.getGroupByName('ProjectTypes');
			return groupAttr.getLabelById( self.projectType );
		},
		get __condition(){
			const groupAttr = getRoot(self).attributeStore.getGroupByName('ProjectConditions');
			return groupAttr.getLabelById( self.projectCondition );
		},
		get __styleList(){
			const groupAttr = getRoot(self).attributeStore.getGroupByName('ProjectStyles');
			let list = [];
			self.projectStyle.map( itemId => list.push( groupAttr.getLabelById( itemId ) ) );
			return list;
			
		},
		get __roomFunctionList(){
			const groupAttr = getRoot(self).attributeStore.getGroupByName('ProjectFunctions');
			let list = [];
			self.projectFunction.map( itemId => list.push(
				{
					value: itemId,
					label: groupAttr.getLabelById( itemId ),
					icon: groupAttr.getParamById( itemId )
				}
			));
			return list;
		},
		get __workList(){
			const groupAttr = getRoot(self).attributeStore.getGroupByName('WorkTypes');
			let list = [];
			self.workTypes.map( itemId => {
				if ( groupAttr.getParamById( itemId ) !== '') {
					list.push({
						label: groupAttr.getLabelById( itemId ),
						icon: groupAttr.getParamById( itemId )
					});
				}
			});
			return list;
		},
		get __sizeWithUnit(){
			let printValue = '-';
			if ( self.projectSize >= 1 ) printValue = self.projectSize;
			return `${printValue} m²`;
		},
		get __budgetWithUnit(){
			if ( self.projectCost >= 1000 ) {
				return self.projectCost/1000 + ' ' + getRoot(self).localeStore.getLabelById('unit__billion');
			} 
			//
			let printValue = '-';
			if ( self.projectCost >=1 ) printValue = self.projectCost;
			return `${printValue} ${getRoot(self).localeStore.getLabelById('unit__million')}`;
		},
		get __roomWithUnit(){
			if ( self.projectRooms === 1 ) {
				return `${self.projectRooms} ${getRoot(self).localeStore.getLabelById('unit__room')}`; 
			} else if ( self.projectRooms > 1 ){
				return `${self.projectRooms} ${getRoot(self).localeStore.getLabelById('unit__rooms')}`; 
			} else {
				return '-';
			}
		},
		get __professionalId(){
			return self.professional.uid || null;
		},
		get __relatedProjects(){
			return self.relatedProjects;
		},
		get __url(){
			return `${ROUTER_PROJECT_BASE}/${self.uid}`; 
		}
	}))
	.actions( self => ({
		afterCreate(){
			//
		},
		getProjectsByStyle: flow (function* getProjectsByStyle(style){
			let selectedStyle = '';
			
			if ( typeof style !== 'undefined' && style !== '') {
				selectedStyle = style;
			} else {
				const randNumber = Math.floor(Math.random() * Math.floor(self.projectStyle.length))
				selectedStyle = self.projectStyle[ randNumber ];
			}
			//
			// console.log( 'selectedStyle', selectedStyle );
			if ( getParent(self, 2).__ready ){
				return getParent(self, 2).getProjectsByStyle({
					style: selectedStyle,
					exceptUid: self.uid
				});
			}
			//
			if ( self.isRelatedLoaded ) return {
				data: self.relatedProjects,
				hasNext: false
			};

			if ( typeof style !== 'undefined' && style !== '') {
				selectedStyle = style;
			} else {
				const randNumber = Math.floor(Math.random() * Math.floor(self.projectStyle.length))
				selectedStyle = self.projectStyle[ randNumber ];
			}
			//
			const collectionRef = firestore.collection('ProjectSnapshot')
				.where('projectStyle', 'array-contains', selectedStyle)
				.orderBy('yearCompletion', 'desc')
				.orderBy('lastModifiedAt', 'desc')
				.limit(DEFAULT_PREVIEW_SIZE + 1);

			try {
				// console.log('fetching same style project from server', selectedStyle);
				const snapshot = yield collectionRef.get();
				self.isRelatedLoaded = true;
				const result = [];
				// console.log(`get ${snapshot.size} records with style ${selectedStyle}`);
				// mapping new data into the 
				snapshot.docs.map( item => {
					if ( item.id !== self.uid && result.length < DEFAULT_PREVIEW_SIZE ){
						result.push({
							...item.data(),
							uid: item.id,
						});
					}
				});
				applySnapshot(self.relatedProjects, result);
				return {
					data: self.relatedProjects,
					hasNext: false
				};
			} catch (e) { 
				// console.log(e);
				throw 'There is some error while fetching.';
			}
		}),
		getDefaultImage(functionName){
			if ( functionName !== undefined && functionName !== 'default'){
				return self.photos.find( photo => photo.hasFunctionType(functionName) ) || self.defaultPhoto;
			}
			return self.defaultPhoto;
		},
		getPhotosByFunctionName(functionName){
			if ( typeof functionName === 'undefined' || functionName === 'default' ) return self.photos;
			return self.photos.filter( photo => photo.hasFunctionType(functionName) );
		},
	}));
	

const ProjectStore = types
	.model('ProjectStore', {
		isReady: false,
		projects: types.array( ProjectModel, []),
		filters: types.optional( ProjectFilterStore, {}),
		isInitialLoaded: false,
		isReachEnd: false,
		lastCursor: types.optional( types.frozen(), {}),
	})
	.views( self => ({
		get __ready(){
			if ( self.projects.length > 0 && self.isInitialLoaded ) return true;			
			return false;
		},
		get __hasNext(){
			return !self.isReachEnd; 
		},
		get __filteredProjects(){
			// console.log('self.filters', self.filters);
			let projects = self.projects.filter( project => project );

			if ( self.filters.filterStyle && self.filters.filterStyle !== 'default' ) {
				projects = projects.filter( project => project.projectStyle.indexOf(self.filters.filterStyle) >= 0 );
			}

			if ( self.filters.filterType && self.filters.filterType !== 'default' ) {
				projects = projects.filter( project => project.projectType.indexOf(self.filters.filterType) >= 0 );
			}

			if ( self.filters.filterFunction && self.filters.filterFunction !== 'default' ) {
				projects = projects.filter( project => project.projectFunction.indexOf(self.filters.filterFunction) >= 0 );
			}

			if ( self.filters.filterCondition && self.filters.filterCondition !== 'default' ) {
				projects = projects.filter( project => project.projectCondition === self.filters.filterCondition );
			}

			if ( self.filters.filterLocation && self.filters.filterLocation !== 'default' ) {
				projects = projects.filter( project => project.city === self.filters.filterLocation );
			}
			
			if ( self.filters.filterSize && self.filters.filterSize !== 'default') {
				const sizeOptions = getRoot(self).attributeStore.getGroupByName('ProjectSizes');
				const pattern = new RegExp( sizeOptions.getParamById(self.filters.filterSize) );
				
				projects = projects.filter( project => pattern.test(project.projectSize) );
			}

			if ( self.filters.filterRooms && self.filters.filterRooms !== 'default') {
				const roomOptions = getRoot(self).attributeStore.getGroupByName('ProjectRoomNo');
				const pattern = new RegExp( roomOptions.getParamById(self.filters.filterRooms) );

				projects = projects.filter( project => pattern.test(project.projectRooms) );
			}

			if ( self.filters.filterBudget && self.filters.filterBudget !== 'default') {
				const budgetOptions = getRoot(self).attributeStore.getGroupByName('ProjectBudgets');
				const pattern = new RegExp( budgetOptions.getParamById(self.filters.filterBudget) );

				projects = projects.filter( project => pattern.test(project.projectCost) );
			}			
			return projects;
		},
	}))
	.actions( self => ({
		// afterCreate(){
		// },
		fetchItems: flow (function* fetchItems({ cursor, sortBy }){
			// skip filtered options
			let query = firestore.collection('ProjectSnapshot')
				.orderBy('viewCount', 'desc')
				.orderBy('projectName', 'asc')
				.limit(DEFAULT_COMPLETE_SIZE);
	
			if ( cursor ) query = query.startAfter( cursor );

			try {
				// console.log("querying");
				const snapshot = yield query.get();
				// check if the new page is available
				if ( snapshot.size < DEFAULT_COMPLETE_SIZE ||
					snapshot.empty ) {
					self.isReachEnd = true;
				}
				// if this is the first time initial
				if ( !self.isInitialLoaded ) {
					self.projects = [];
				}
				// mapping new data into the 
				const items = [];
				snapshot.docs.map( (item, index) => {
					const data = item.data();
					items.push({
						...data,
						uid: item.id,
						isValid: true
					});
					if ( index === snapshot.size - 1) self.lastCursor = item;
				}); 
				applySnapshot( self.projects, items);
				self.isInitialLoaded = true;
				return true;
			} catch (error) {
				console.error(error);
			}
		}),
		fetchNext: flow ( function* fetchNext(){
			if ( !self.__hasNext ) return false;
			try{
				yield self.fetchItems({
					cursor: self.lastCursor
				});
				return true;
			} catch (error) {
				console.error(error);
			}
		}),
		getDetailById: flow ( function* getDetailById(uid){
			const foundProject = self.projects.find( project => project.uid === uid );
			if (foundProject) {
				fetch(`${API_REST_VIEWS}/projects/${uid}`, {
					method: 'POST',
					credentials: 'include'
				});
				return foundProject;
			}
			//
			const projectRef = firestore.doc(`ProjectSnapshot/${uid}`);

			try {
				// console.log( 'fetching from server the id ', uid );
				const doc = yield projectRef.get();
				if ( doc.exists ) {
					self.projects.push( ProjectModel.create({
						...doc.data(),
						uid: doc.id,
						isValid: true,
					}));
					fetch(`${API_REST_VIEWS}/projects/${uid}`, {
						method: 'POST',
						credentials: 'include'
					});
					return self.projects[ self.projects.length - 1 ];
				} else {
					return ProjectModel.create({
						uid: 'not-found',
						projectRooms: 0,
						yearCompletion: 0,
						isValid: false
					});
				}
			} catch (e) {
				throw `Please see the issue ${e}`;
			}
		}),
		getProjectsByProfileId({uid}){
			if ( !self.__ready ) return { data: [], hasNext: false };
			//
			let projects = self.projects.filter( project => project );
			//
			projects = projects.filter( project => project.professional.uid === uid );

			if ( projects.length >= DEFAULT_COMPLETE_SIZE ){
				return {
					data: projects.slice(0, DEFAULT_COMPLETE_SIZE),
					hasNext: false
				};
			}

			return {
				data: projects,
				hasNext: false
			};
		},
		getProjectsByStyle({style, exceptUid}){
			if ( !self.__ready ) return { data: [], hasNext: false };
			//
			let projects = self.projects.filter( project => project );
			//
			projects = projects.filter( project => {
				if ( project.uid === exceptUid ) return false;
				return project.projectStyle.indexOf(style) >= 0 ? true : false;
			});
			//
			return {
				data: projects.slice(0, DEFAULT_PREVIEW_SIZE),
				hasNext: false
			};
		},
		getListByFilters({filters, sortBy='', pageNo=1}){
			if ( !self.__ready ) return { data: [], hasNext: false };

			applySnapshot( self.filters, filters );
			let projects = self.projects.filter( project => project );

			if ( sortBy !== '' ){
				switch (sortBy){
				case 'time-asc': 
					projects = projects.sort( (a,b) => a.yearCompletion - b.yearCompletion );
					break;
				case 'time-desc': 
					projects = projects.sort( (a,b) => b.yearCompletion - a.yearCompletion );
					break;
				case 'budget-asc': 
					projects = projects.sort( (a,b) => a.projectCost - b.projectCost );
					break;
				case 'budget-desc': 
					projects = projects.sort( (a,b) => b.projectCost - a.projectCost );
					break;
				default: 
					break;
				}
			}

			if ( self.filters.filterStyle && self.filters.filterStyle !== 'default' ) {
				projects = projects.filter( project => project.projectStyle.indexOf(self.filters.filterStyle) >= 0 );
			}

			if ( self.filters.filterType && self.filters.filterType !== 'default' ) {
				projects = projects.filter( project => project.projectType.indexOf(self.filters.filterType) >= 0 );
			}

			if ( self.filters.filterFunction && self.filters.filterFunction !== 'default' ) {
				projects = projects.filter( project => project.projectFunction.indexOf(self.filters.filterFunction) >= 0 );
			}

			if ( self.filters.filterCondition && self.filters.filterCondition !== 'default' ) {
				projects = projects.filter( project => project.projectCondition === self.filters.filterCondition );
			}

			if ( self.filters.filterLocation && self.filters.filterLocation !== 'default' ) {
				projects = projects.filter( project => project.city === self.filters.filterLocation );
			}
			
			if ( self.filters.filterSize && self.filters.filterSize !== 'default') {
				const sizeOptions = getRoot(self).attributeStore.getGroupByName('ProjectSizes');
				const pattern = new RegExp( sizeOptions.getParamById(self.filters.filterSize) );
				
				projects = projects.filter( project => pattern.test(project.projectSize) );
			}

			if ( self.filters.filterRooms && self.filters.filterRooms !== 'default') {
				const roomOptions = getRoot(self).attributeStore.getGroupByName('ProjectRoomNo');
				const pattern = new RegExp( roomOptions.getParamById(self.filters.filterRooms) );

				projects = projects.filter( project => pattern.test(project.projectRooms) );
			}

			if ( self.filters.filterBudget && self.filters.filterBudget !== 'default') {
				const budgetOptions = getRoot(self).attributeStore.getGroupByName('ProjectBudgets');
				const pattern = new RegExp( budgetOptions.getParamById(self.filters.filterBudget) );

				projects = projects.filter( project => pattern.test(project.projectCost) );
			}
			//
			const itemSize = DEFAULT_PAGE_SIZE * pageNo;
			//
			if ( itemSize >= projects.length ) return {
				data: projects,
				hasNext: false
			};
			//
			return {
				data: projects.slice(0, itemSize),
				hasNext: true
			};
		}
	}));

export { ProjectModel, ProjectStore };