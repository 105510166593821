import { types, applySnapshot } from 'mobx-state-tree';

const FilterBaseModel = types
	.model({})
	.views( self => ({
		//
	}))
	.actions( self => ({
		initial(params){
			applySnapshot( self, params );
		},
		formatSelectValue (source){
			let formattedSource = [];
			if (source){
				formattedSource = Object.keys(source).reduce(
					(newSource, keyName) => {
						newSource.push({
							key: keyName,
							label: source[keyName],
							value: keyName
						});
						return newSource;
					}, []
				);
			}
			return formattedSource;
		},
		changeFilter(event){
			self[event.target.name] = event.target.value;
		},
		changeFilterByName({name, value}){
			// const dataType = getChildType(self, name).name;
			// if ( dataType === 'number' && value === 'default'){
			// 	self[name] = 0;
			// } else {
			self[name] = value;
			// }	
		},
		changeFilterNumeric(event){
			self[event.target.name] = parseInt(event.target.value);
		},
		changeRangeFilter(event){
			self[event.target.name] = isNaN(event.target.value) ? 0 : parseInt(event.target.value);
		},
	}));

const ProjectFilterStore = types.compose(
	FilterBaseModel,
	types.model('ProjectFilterStore', {
		filterStyle: types.optional( types.string, 'default'),
		filterType: types.optional( types.string, 'default'),
		filterFunction: types.optional( types.string, 'default'),
		filterCondition: types.optional( types.string, 'default'),
		filterLocation: types.optional( types.string, 'default'),
		filterSize: 'default',
		filterRooms: 'default',
		filterBudget: 'default',
		isAdvancedMode: types.optional( types.boolean, false),
	})
		.views( self => ({
			get filterParams(){
				return  {
					'type': self.filterType !== 'default' ? self.filterType : null,
					'style': self.filterStyle !== 'default' ? self.filterStyle : null,
					'condition': self.filterCondition !== 'default' ? self.filterCondition : null,
					'function': self.filterFunction !== 'default' ? self.filterFunction : null,
					'location': self.filterLocation !== 'default' ? self.filterLocation : null,
					'size': self.filterSize !== 'default' ? self.filterSize : null,
					'budget': self.filterBudget !== 'default' ? self.filterBudget : null,
					'rooms': self.filterRooms !== 'default' ? self.filterRooms : null,
				};
			},
			get initialFilterParams(){
				return  {
					'type': null,
					'style': null,
					'condition': null,
					'function': null, 
					'location': null, 
					'size': null,
					'budget': null, 
					'rooms': null,
				};
			}
		}))
		.actions( self => ({
			toggleMode(){
				self.isAdvancedMode = !self.isAdvancedMode;	
			},
			resetFilters(){
				self.filterStyle = 'default';
				self.filterType = 'default';
				self.filterFunction = 'default';
				self.filterCondition = 'default';
				self.filterLocation = 'default';
				self.filterSize = 'default';
				self.filterRooms = 'default';
				self.filterBudget = 'default';
			},
		})
		)
);

const ProfileFilterStore = types.compose(
	FilterBaseModel,
	types.model('ProfileFilterStore', {
		filterWork: types.optional( types.string, 'default'),
		filterLocation: types.optional( types.string, 'default'),
		filterName: types.optional( types.string, ''),
	})
		.views( self => ({
			get filterParams(){
				return  {
					'keyword': self.filterName !== '' ? self.filterName : null,
					'location': self.filterLocation !== 'default' ? self.filterLocation : null,
					'work': self.filterWork !== 'default' ? self.filterWork : null,
				};
			},
			get initialFilterParams(){
				return  {
					'keyword': null, 
					'location': null, 
					'work': null,
				};
			},
		}))
		.actions( self => ({
			resetFilters(){
				self.filterWork = 'default';
				self.filterLocation = 'default';
				self.filterName = '';
			},
		}))
);

export { FilterBaseModel, ProjectFilterStore, ProfileFilterStore };